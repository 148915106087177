<template>
  <el-dialog
    title="管理员"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="所属区域" prop="aCityID">
        <el-select v-model="temp.aCityID" placeholder="请选择">
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in list_citys"
            :key="item.id"
            :label="item.cName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名" prop="aUserName">
        <el-input v-model="temp.aUserName"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="aUserPwd">
        <el-input type="password" v-model="temp.aUserPwd"></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否系统管理员" prop="aIsAdministrator">
        <el-input v-model="temp.aIsAdministrator"></el-input>
      </el-form-item> -->
      <el-form-item label="所属角色" prop="aRoleID">
        <el-select v-model="temp.aRoleID" placeholder="请选择">
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in list_roles"
            :key="item.id"
            :label="item.rName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位名称" prop="aCpyName">
        <el-input v-model="temp.aCpyName"></el-input>
      </el-form-item>
      <el-form-item label="单位地址" prop="aCpyAddr">
        <el-input v-model="temp.aCpyAddr"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="aContact">
        <el-input v-model="temp.aContact"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="aTel">
        <el-input v-model="temp.aTel"></el-input>
      </el-form-item>
      <!-- <el-form-item label="经纬度" prop="aLngLat">
        <el-input v-model="temp.aLngLat"></el-input>
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      this.getList();
    },
    row_type(val) {
      Object.assign(this.temp, val);
    },
  },
  data() {
    return {
      list_citys: [],
      list_roles: [],
      dialogVisible: false,
      temp: {
        id: "",
        aCityID: "",
        aUserName: "",
        aUserPwd: "",
        // aIsAdministrator: "",
        aRoleID: "",
        aCpyName: "",
        aCpyAddr: "",
        aContact: "",
        aTel: "",
        aLngLat: "",
        // aIs_del: "",
      },
      rules: {
        // aCityID: [
        //   {
        //     required: true,
        //     message: "所属区域必填",
        //     trigger: "blur",
        //   },
        // ],
        aUserName: [
          {
            required: true,
            message: "用户名必填",
            trigger: "blur",
          },
        ],
        aUserPwd: [
          {
            required: true,
            message: "密码必填",
            trigger: "blur",
          },
        ],
        // aIsAdministrator: [
        //   {
        //     required: true,
        //     message: "是否系统管理员必填",
        //     trigger: "blur",
        //   },
        // ],
        aRoleID: [
          {
            required: true,
            message: "所属角色必填",
            trigger: "blur",
          },
        ],
        aCpyName: [
          {
            required: true,
            message: "单位名称必填",
            trigger: "blur",
          },
        ],
        // aCpyAddr: [
        //   {
        //     required: true,
        //     message: "单位地址必填",
        //     trigger: "blur",
        //   },
        // ],
        aContact: [
          {
            required: true,
            message: "联系人必填",
            trigger: "blur",
          },
        ],
        aTel: [
          {
            required: true,
            message: "联系电话必填",
            trigger: "blur",
          },
        ],
        // aLngLat: [
        //   {
        //     required: true,
        //     message: "经纬度必填",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getList() {
      this.$api.admins_add_other({}).then((res) => {
        this.list_citys = res.data.list_citys;
        this.list_roles = res.data.list_roles;
      });
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.admins_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
